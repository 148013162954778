<template>
    <b-row class="justify-content-between">
      <b-col sm="12" lg="10">
        <!-- Filter tanggal -->
        <section class="d-flex items-center">
          <b-form-group :label="sum_date == 2 ? `Tanggal Awal` : 'Tanggal'" class="mr-2">
            <b-form-input type="date" v-model="form.tgl_awal"></b-form-input>
          </b-form-group>
          <b-form-group v-if="sum_date == 2" label="Tanggal Akhir" class="mr-2">
            <b-form-input type="date" v-model="form.tgl_akhir"></b-form-input>
          </b-form-group>
          <slot></slot>
        </section>
        <!-- / -->
      </b-col>
      <b-col sm="12" lg="2" class="d-flex justify-content-end">
        <b-form-group label="-">
          <slot name="dropdown-item" :form="form">
            <b-dropdown variant="outline-primary" text="Export" v-if="type_export.length > 1">
              <b-dropdown-item href="#" @click.prevent="onExport('pdf')">Export ke PDF</b-dropdown-item>
              <b-dropdown-item href="#" @click.prevent="onExport('excel')">Export ke Excel</b-dropdown-item>
            </b-dropdown>
            <b-button variant="outline-primary" @click.prevent="onExport('pdf')" v-if="type_export.length == 1">Export</b-button>
          </slot>
        </b-form-group>
      </b-col>
    </b-row>
</template>
<script>
import {BFormInput, BRow, BCol, BFormGroup, BDropdown, BDropdownItem, BButton} from 'bootstrap-vue'
export default {
    components: {
        BFormInput, BRow, BCol, BFormGroup, BDropdown, BDropdownItem, BButton
    },
    data: () => ({
      form: {
        tgl_awal: null,
        tgl_akhir: null
      }
    }),
    methods: {
      onExport(type_export) {
        let typ = type_export
        if(this.type_export.length == 1 && this.type_export[0] == 'excel') {
          typ = 'excel'
        }
        this.$emit('onExport', {
          tgl_awal: this.form.tgl_awal, 
          tgl_akhir: this.form.tgl_akhir, 
          type_export: typ}
        )
      }
    },
    props: {
        type_export: {
            type: Array,
            default: () => ['excel']
        },
        sum_date: {
            type: Number,
            default: () => 2
        }
    }
}
</script>